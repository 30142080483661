export const SENT_TO_FINANCIER = 7
export const CONTRACT_SIGNED = 13
export const LOST_STATUS = 19;
export const PAYMENT_VERFICATION = 16
export const BASIC_DETAILS = 2
export const SOFT_APPROVAL_RECEIVED = 3
export const CUSTOMER_PERSONAL_DETAIL_FILLED = 5
export const CI_SCHEDULED = 9
export const CI_REPORT_CHECK = 10
export const FINANCIER_APPROVAL = 11
export const LOAN_DISBURSED = 15
export const COMPUTATION_GENERATED = 21
export const ON_HOLD = 22
export const INCENTIVE_PAID = 17
export const INCENTIVE_RECEIVED = 18
export const SOFT_APPROVAL_PARENT_ID = 13
export const SOFT_APPROVAL_CHILD_ID = 73
export const MAX_UPLOAD = 5
export const LOAN_APPROVAL_PARENT_ID = 16
export const LOAN_APPROVAL_CHILD_ID = 81
export const BUSINESS_LINE = "UCRF"
export const LOAN_APPROVED = 12
export const CONTRACT_SIGNED_DOC_CHILD_ID = 80
export const LOAN_DISBURSED_DOC_CHILD_ID = 82
export const DEALER_INCENTIVE_PAID_DOC_CHILD_ID = 83
export const DEALER_INCENTIVE_RECEIVED_DOC_CHILD_ID = 84
export const DealershipCategory = [
    { value: "ucd_cm", label: "Used Car Dealer - Car Mall" },
    { value: "ucd_s", label: "Used Car Dealer - Store" },
    { value: "ucd_h", label: "Used Car Dealer - Home" },
    { value: "uc_a", label: "Used Car Finance - Agent" },
    { value: "ucrf_a", label: "Used Car Refinance - Agent" },
    { value: "uc_ps", label: "Used Car - Private Seller" },
    { value: "cd", label: "Corporate Dealer" },
];
